<template>
  <v-dialog
    v-model="value"
    max-width="400"
  >
    <v-card class="pa-3">
      <v-card-title class="headline">
        Please review before sending out the emails
      </v-card-title>
      <v-card-text>
        Your about to send an email to {{ userCount }} users
      </v-card-text>
      <v-card-text>
        Affected languages: {{ affectedLanguages.length ? affectedLanguages.join(', ') : 'ALL' }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <app-button
          secondary
          @click="$emit('cancel')"
        >
          Cancel
        </app-button>
        <app-button
          primary
          @click="$emit('send')"
        >
          Send
        </app-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmEmailDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    userCount: {
      type: Number,
      required: true
    },
    affectedLanguages: {
      type: Array,
      default: () => ([])
    }
  }
};
</script>

<style lang="scss" scoped>
  .avatar {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
  }
</style>

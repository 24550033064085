<template>
  <v-row>
    <v-col
      cols="12"
      :md="original && value ? 6 : 12"
    >
      <email-campaign
        :had-email-campaign="!!original"
        :email-campaign="!!value || checkByDefault"
        :show-coaching-number="showCoachingNumber"
        :disabled="disabled"
        @campaignSelected="campaignSelected"
        @campaignDateSelected="$emit('input', $event)"
        @coachingNumber="$emit('coachingNumber', $event)"
      />
    </v-col>
    <v-col
      v-if="original && value"
      cols="12"
      md="6"
    >
      <email-campaign-dates
        :subscribed="true"
        :project="project"
        :start-date="value"
      />
    </v-col>
  </v-row>
</template>

<script>
import EmailCampaign from '@/components/projects/EmailCampaign';
import EmailCampaignDates from '@/components/projects/EmailCampaignDates';

export default {
  components: {
    EmailCampaign,
    EmailCampaignDates
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    original: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    project: {
      type: Object,
      default: () => ({})
    },
    showCoachingNumber: {
      type: Boolean,
      default: false
    },
    checkByDefault: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    campaignSelected(selected) {
      if (selected && !this.original) this.$emit('input', new Date().toISOString().substr(0, 10));
      else if (selected && this.original) this.$emit('input', this.original);
      else this.$emit('input', null);
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .col-12 {
  padding: 0 0.6rem;
}
::v-deep .view-dates {
  margin-top: 0.6rem;
}
.row {
  align-items: end;
}
</style>

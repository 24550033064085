<template>
  <div>
    <v-menu
      slot="header-actions"
      left
      buttom
    >
      <template #activator="{ on }">
        <v-btn
          color="grey darken-1"
          text
          icon
          v-on="on"
        >
          <v-icon>more_vert</v-icon>
        </v-btn>
      </template>
      <v-list>
        <!-- View Project in Portal -->
        <v-list-item @click="viewProjectInPortal">
          View in Portal
        </v-list-item>

        <!-- Mark Project as Alumni -->
        <v-tooltip
          v-if="(!isPending || project.isApproved) && !project.isAlumni"
          top
        >
          <template #activator="{ on }">
            <v-list-item
              :class="hasPendingChanges ? 'grey--text' : 'red--text'"
              v-on="hasPendingChanges ? on : undefined"
              @click="projectToAlumni = hasPendingChanges ? undefined : { ...project }"
            >
              Mark as Alumni
            </v-list-item>
          </template>
          <span>Please save first your pending changes</span>
        </v-tooltip>

        <!-- Unmark as Alumni -->
        <v-tooltip
          v-if="project.isAlumni"
          top
        >
          <template #activator="{ on }">
            <v-list-item
              :class="hasPendingChanges ? 'grey--text' : 'red--text'"
              v-on="hasPendingChanges ? on : undefined"
              @click="projectRemoveAlumni = hasPendingChanges ? undefined : { ...project }"
            >
              Unmark as Alumni
            </v-list-item>
          </template>
          <span>Please save your pending changes first!</span>
        </v-tooltip>

        <!-- Change owner -->
        <change-owner-option
          :project="project"
          :from-board="true"
          @click="$emit('showChangeOwner');"
        />

        <!-- Delete Project -->
        <v-tooltip top>
          <template #activator="{ on }">
            <v-list-item
              :class="hasPendingChanges ? 'grey--text' : 'red--text'"
              v-on="hasPendingChanges ? on : undefined"
              @click="projectToDelete = hasPendingChanges ? undefined : { ...project }"
            >
              Delete Project
            </v-list-item>
          </template>
          <span>Please save first your pending changes</span>
        </v-tooltip>

        <!-- Create Certificate -->
        <create-certificate-option
          v-if="!isPending && project.parseObject"
          :project="project"
          :from-board="true"
          @click="showCreateCertificate"
        />
      </v-list>
    </v-menu>
    <confirmation-dialog
      v-model="projectToDelete"
      title="Delete"
      :text="deleteText"
      @confirm="deleteProject"
    />
    <confirmation-dialog
      v-model="projectToAlumni"
      title="Alumni"
      text="Are you sure that you want to mark this project as alumni?"
      @confirm="markProjectAsAlumni"
    />
    <confirmation-dialog
      v-model="projectRemoveAlumni"
      title="Alumni"
      :text="`Are you sure that this project should no longer be marked as alumni?
      P.s. The learnings video, URL and comments won't be lost.`"
      @confirm="unmarkProjectAsAlumni"
    />
  </div>
</template>

<script>
import { PENDING } from '@kickbox/common-util';
import { config } from '@/utils';
import { confirmationDialog } from '@/components/dialogs';
import { projectService } from '@/services';
import CreateCertificateOption from './CreateCertificateOption';
import ChangeOwnerOption from '@/components/projects/ChangeOwnerOption';

export default {
  components: {
    ChangeOwnerOption,
    confirmationDialog,
    CreateCertificateOption
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    hasPendingChanges: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      projectToAlumni: undefined,
      projectRemoveAlumni: undefined,
      projectToDelete: undefined
    };
  },
  computed: {
    isPending() {
      return this.project && this.project.phase.name === PENDING.name;
    },
    deleteText() {
      return 'Are you sure that you want to delete this project? <b>The action cannot be undone</b> and the coin balance of the project will be set to zero.';
    }
  },
  methods: {
    viewProjectInPortal() {
      window.open(`${config.portalUrl}/#/project/${this.project.id}`, '_blank');
    },

    async markProjectAsAlumni() {
      try {
        // Mark project as alumni
        await projectService.manageAlumni(this.project, true);
        this.$emit('projectMarkedAsAlumni');
      } catch (error) {
        // Throw error
        this.$store.dispatch('showSnackBar', {
          text: 'An error occurred while marking the project as alumni!'
        });
        throw new Error(error);
      }
    },

    async unmarkProjectAsAlumni() {
      try {
        // Unmark project as alumni
        await projectService.manageAlumni(this.project, false);
        this.$emit('projectUnmarkedAsAlumni');
      } catch (error) {
        // Throw error
        this.$store.dispatch('showSnackBar', {
          text: 'An error occurred while unmarking the project as alumni!'
        });
        throw new Error(error);
      }
    },

    async deleteProject() {
      try {
        // Delete the project and load the project store
        projectService.deleteProject(this.project.id);
        await projectService.getDashboardProjects();
        this.$emit('projectDeleted');
      } catch (error) {
        // Throw error
        this.$store.dispatch('showSnackBar', {
          text: 'An error occurred while deleting the project!'
        });
        throw new Error(error);
      }
    },

    showCreateCertificate() {
      this.$emit('showCreateCertificate');
    },

    changeOwner() {
      this.$emit('changeOwner');
    }
  }
};
</script>

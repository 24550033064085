<template>
  <base-project-dialog
    :max-width="600"
    persistent
    :project="value"
    :title="`${title} Project: ${value.title}`"
    :value="!!value.id"
  >
    <!-- Dialog Text -->
    <v-card-text>
      <p
        class="mt-3"
        style="white-space: pre-line"
        v-html="$sanitize(text)"
      />
      <p
        v-if="notUndoable"
        class="body-2 red--text"
      >
        This action cannot be undone.
      </p>
    </v-card-text>

    <v-divider />

    <!-- Dialog Actions -->
    <v-card-actions slot="actions">
      <!-- Cancel -->
      <app-button
        cancel
        secondary
        @click="$emit('input', undefined)"
      >
        Cancel
      </app-button>

      <v-spacer />

      <!-- Confirm -->
      <app-button
        cancel
        @click="confirm"
      >
        Confirm
      </app-button>
    </v-card-actions>
  </base-project-dialog>
</template>

<script>
import BaseProjectDialog from './_BaseProjectDialog';

export default {
  components: { BaseProjectDialog },

  props: {
    value: {
      default: () => ({ creator: {} }),
      type: Object
    },
    title: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
    notUndoable: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    confirm() {
      this.$emit('confirm');
      this.$emit('input', undefined);
    }
  }
};
</script>

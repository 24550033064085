<template>
  <v-row no-gutters>
    <!-- Hint Tooltip -->
    <v-col
      v-if="!!tooltip"
      class="shrink"
    >
      <v-tooltip
        :max-width="240"
        right
      >
        <template #activator="{ on }">
          <v-icon
            class="tooltip-icon ml-0 mr-2"
            color="grey lighten-1"
            v-on="on"
          >
            {{ tooltipIcon }}
          </v-icon>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </v-col>

    <!-- Input Component Slot -->
    <v-col>
      <slot>
        <v-text-field />
      </slot>
    </v-col>

    <!-- Restore Button -->
    <v-col
      v-if="!!restoreMessage"
      class="shrink"
    >
      <v-tooltip
        :max-width="240"
        left
      >
        <template #activator="{ on }">
          <v-btn
            class="ml-1 mr-0 mt-4"
            color="grey"
            text
            icon
            v-on="on"
            @click="$emit('restore')"
          >
            <v-icon>{{ restoreIcon }}</v-icon>
          </v-btn>
        </template>
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="restoreMessage" />
        <!-- eslint-disable vue/no-v-html -->
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    /**
     * Set an alternate icon for the `restore` button.
     */
    restoreIcon: {
      default: 'restore',
      type: String
    },

    /**
     * Set an alternate `restore` message.
     */
    restoreMessage: {
      default: undefined,
      type: String
    },

    /**
     * Set an optional tooltip message.
     */
    tooltip: {
      default: undefined,
      type: String
    },

    /**
     * Set an alternate tooltip icon.
     */
    tooltipIcon: {
      default: 'help',
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip-icon {
  margin-top: 20px;
  cursor: help;
}
</style>

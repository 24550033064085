<template>
  <v-dialog
    v-bind="$attrs"
    :value="value"
    @input="$emit('input', $event)"
  >
    <v-card>
      <!-- Header Slot with Default Content -->
      <slot name="header">
        <v-toolbar
          flat
          color="grey lighten-3"
          tabs
        >
          <slot name="toolbar-chip" />
          <v-toolbar-title class="ml-2">
            {{ title || project.title }}
          </v-toolbar-title>
          <v-spacer />
          <span class="ml-4 mr-1">by</span>
          <avatar
            color="white"
            chip
            :size="36"
            text-color="grey darken-3"
            :user="project.creator"
          />
          <slot name="header-actions" />

          <slot
            slot="extension"
            name="extension"
          />
        </v-toolbar>
      </slot>

      <!-- Main Slot with Default Content -->
      <slot>
        <v-card-text class="font-italic text-grey">
          Dialog Content
        </v-card-text>
      </slot>

      <!-- Actions Slot with Default Content -->
      <slot name="actions">
        <v-divider />
        <v-card-actions>
          <app-button
            cancel
            secondary
            @click="$emit('input', undefined)"
          >
            Close
          </app-button>
        </v-card-actions>
      </slot>
    </v-card>
  </v-dialog>
</template>

<script>
import { Avatar } from '@/components/widgets';

export default {
  components: { Avatar },
  inheritAttrs: false,

  props: {
    /**
     * Specifies the project that is associated with this dialog.
     *
     * @type {Object}
     */
    project: {
      default: () => ({ creator: {} }),
      type: Object
    },

    /**
     * Provides the ability to assign an alternate dialog title.
     *
     * The dialog will display the `project.title` by default.
     *
     * @type {string}
     */
    title: {
      default: undefined,
      type: String
    },

    value: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}
</style>

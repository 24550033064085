<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      projectId: undefined,
      showModal: false
    };
  },

  computed: {
    ...mapGetters(['dashboardProjects']),

    project() {
      if (!this.projectId) return undefined;
      const project = this.dashboardProjects.find((p) => p.id === this.projectId);
      return project || {
        phase: {},
        creator: {},
        bankAccount: {},
        coaches: [],
        notes: [],
        projectUnit: {}
      };
    }
  },

  methods: {
    /**
     * Closes the modal and destroys it after the closing animation is played.
     */
    closeModal() {
      this.showModal = false;
      setTimeout(() => {
        this.projectId = undefined;
        this.imageHasChange = false;
      }, 300);
    },

    /**
     * Creates and displays a new modal.
     *
     * @param {string} projectId The ID of the target project.
     */
    openModal(projectId) {
      this.projectId = projectId;
      this.$nextTick(() => { this.showModal = true; });
    }
  }
};
</script>

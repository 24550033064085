var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"slot":"header-actions","left":"","buttom":""},slot:"header-actions",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"grey darken-1","text":"","icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.viewProjectInPortal}},[_vm._v(" View in Portal ")]),((!_vm.isPending || _vm.project.isApproved) && !_vm.project.isAlumni)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({class:_vm.hasPendingChanges ? 'grey--text' : 'red--text',on:{"click":function($event){_vm.projectToAlumni = _vm.hasPendingChanges ? undefined : Object.assign({}, _vm.project)}}},_vm.hasPendingChanges ? on : undefined),[_vm._v(" Mark as Alumni ")])]}}],null,false,4262576453)},[_c('span',[_vm._v("Please save first your pending changes")])]):_vm._e(),(_vm.project.isAlumni)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({class:_vm.hasPendingChanges ? 'grey--text' : 'red--text',on:{"click":function($event){_vm.projectRemoveAlumni = _vm.hasPendingChanges ? undefined : Object.assign({}, _vm.project)}}},_vm.hasPendingChanges ? on : undefined),[_vm._v(" Unmark as Alumni ")])]}}],null,false,1148990755)},[_c('span',[_vm._v("Please save your pending changes first!")])]):_vm._e(),_c('change-owner-option',{attrs:{"project":_vm.project,"from-board":true},on:{"click":function($event){return _vm.$emit('showChangeOwner');}}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({class:_vm.hasPendingChanges ? 'grey--text' : 'red--text',on:{"click":function($event){_vm.projectToDelete = _vm.hasPendingChanges ? undefined : Object.assign({}, _vm.project)}}},_vm.hasPendingChanges ? on : undefined),[_vm._v(" Delete Project ")])]}}])},[_c('span',[_vm._v("Please save first your pending changes")])]),(!_vm.isPending && _vm.project.parseObject)?_c('create-certificate-option',{attrs:{"project":_vm.project,"from-board":true},on:{"click":_vm.showCreateCertificate}}):_vm._e()],1)],1),_c('confirmation-dialog',{attrs:{"title":"Delete","text":_vm.deleteText},on:{"confirm":_vm.deleteProject},model:{value:(_vm.projectToDelete),callback:function ($$v) {_vm.projectToDelete=$$v},expression:"projectToDelete"}}),_c('confirmation-dialog',{attrs:{"title":"Alumni","text":"Are you sure that you want to mark this project as alumni?"},on:{"confirm":_vm.markProjectAsAlumni},model:{value:(_vm.projectToAlumni),callback:function ($$v) {_vm.projectToAlumni=$$v},expression:"projectToAlumni"}}),_c('confirmation-dialog',{attrs:{"title":"Alumni","text":"Are you sure that this project should no longer be marked as alumni?\n    P.s. The learnings video, URL and comments won't be lost."},on:{"confirm":_vm.unmarkProjectAsAlumni},model:{value:(_vm.projectRemoveAlumni),callback:function ($$v) {_vm.projectRemoveAlumni=$$v},expression:"projectRemoveAlumni"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<script>
const EXISTS = (value) => (Array.isArray(value) ? value.length > 0 : (!!value || value === 0));

export default {
  inheritAttrs: false,

  props: {
    /**
     * Sets the character or select counter for the input field.
     *
     * You can pass a `boolean` to simply display a character counter. This is purely visual and
     * has no impact on value validation.
     *
     * If you pass a number, an additional top value will be displayed in the counter and an error
     * will show when the maximal amount of characters is reached (or surpassed).
     *
     * This also supports arrays containing two numbers, the minimal and maximal character count.
     * It will be displayed the same way as if a number was passed, but will display an error if
     * too few characters / items are entered.
     *
     * @type {boolean|number|number[]}
     */
    counter: {
      default: false,
      type: [Boolean, Number, Array]
    },

    /**
     * Sets the original value of the input field.
     *
     * When an original value is provided and the current input value differs from it, a `restore`
     * button appears next to the input field, which allows the user to restore the original value.
     *
     * @type {*}
     */
    original: {
      default: undefined
    },

    /**
     * Sets the message that is displayed if the input field requires a value but has none.
     *
     * @type {string}
     */
    requiredMessage: {
      default: 'This field is required!',
      type: String
    },

    /**
     * Sets the message that is displayed in the tooltip on the `restore` button.
     *
     * @type {string}
     */
    restoreMessage: {
      default: 'Restore the original value.',
      type: String
    },

    /**
     * Sets the error message that is displayed when the input value is too high or too big.
     *
     * @type {string}
     */
    tooHighMessage: {
      default: 'Too high!',
      type: String
    },

    /**
     * Sets the error message that is displayed when the input value is too long or has too many
     * items.
     *
     * @type {string}
     */
    tooLongMessage: {
      default: 'Too long!',
      type: String
    },

    /**
     * Sets the error message that is displayed when the input value is too low or too small.
     *
     * @type {string}
     */
    tooLowMessage: {
      default: 'Too low!',
      type: String
    },

    /**
     * Sets the text that is displayed in the element tooltip.
     *
     * If no value is provided, the tooltip icon will not be displayed.
     *
     * @type {string}
     */
    tooltip: {
      default: undefined,
      type: String
    },

    /**
     * Sets the error message that is displayed when the input value is too short or has too few
     * items.
     *
     * @type {string}
     */
    tooShortMessage: {
      default: 'Too short!',
      type: String
    }
  },

  computed: {
    /**
     * Decides whether the `restore` button should be displayed.
     *
     * @returns {boolean}
     */
    canRestore() {
      if (this.original === undefined || this.$attrs.disabled || this.$attrs.readonly) return false;
      if (typeof this.original === 'object') return !this.$lodash.isEqual(this.original, this.$attrs.value);
      if (this.$attrs.type === 'number') return Number(this.$attrs.value) !== Number(this.original);
      return this.original !== this.$attrs.value;
    },

    /**
     * Determines whether to display a character / item counter and its maximum.
     *
     * @returns {boolean|number}
     */
    computedCounter() {
      return this.counter && Array.isArray(this.counter)
        ? this.counter[1]
        : this.counter;
    },

    /**
     * Builds the final input field label.
     *
     * Adds an `*`-character at the end of the label if the field is required.
     *
     * @returns {string}
     */
    computedLabel() {
      return !this.$attrs.label || !this.isRequired
        ? this.$attrs.label
        : `${this.$attrs.label} *`;
    },

    /**
     * Builds the final restoration message.
     *
     * Adds the original value of the field to the bottom of the message.
     *
     * @returns {string}
     */
    computedRestorationMessage() {
      return this.canRestore
        ? `${this.restoreMessage}<br /><br />Original Value:<br /><i>${this.original}</i>`
        : undefined;
    },

    /**
     * Builds the base rules for the input according to its properties.
     *
     * Any rules added via the `rules` property will be added to the basic rules.
     *
     * @returns {((value) => boolean|string)[]}
     */
    computedRules() {
      const self = this;
      const rules = [];
      let maxLength;
      let minLength;

      if (Array.isArray(this.counter) || typeof this.counter === 'number') {
        maxLength = Array.isArray(this.counter) ? this.counter[1] : this.counter;
        minLength = Array.isArray(this.counter) ? this.counter[0] : undefined;
      }

      rules.push((value) => {
        if (value === this.original) return true;

        // Check if value is set
        if (self.isRequired && !EXISTS(value)) return this.requiredMessage;

        // Check if value is in bounds
        if (this.$attrs.type === 'number' || this.$attrs.type === 'date') {
          if (EXISTS(this.$attrs.max) && value > this.$attrs.max) return this.tooHighMessage;
          if (EXISTS(this.$attrs.min) && value < this.$attrs.min) return this.tooLowMessage;
        }

        // Check if value length is in bounds
        if (maxLength && value.length > maxLength) return this.tooLongMessage;
        if (minLength && value.length < minLength) return this.tooShortMessage;

        // Value is valid
        return true;
      });

      // Append manually set rules
      if (this.$attrs.rules && this.$attrs.rules.length > 0) {
        rules.push(...this.$attrs.rules);
      }

      return rules;
    },

    /**
     * Determines whether this input field is required to be filled.
     *
     * @returns {boolean}
     */
    isRequired() {
      return !([undefined, false].indexOf(this.$attrs.required) > -1);
    }
  },

  methods: {
    /**
     * Restores the original value of the field.
     */
    restoreOriginal() {
      this.$emit('input', this.original);
    }
  }
};
</script>

<template>
  <v-menu
    bottom
    left
  >
    <template #activator="{ on }">
      <!-- Avatar Chip with User Name -->
      <v-chip
        v-if="chip"
        class="chip-with-text pa-0"
        :color="color"
        :outlined="outlined"
        :text-color="textColor"
        pill
        v-on="!!user.email ? on : undefined"
      >
        <v-avatar
          class="avatar"
          :size="size"
          :style="{ backgroundImage: `url(${avatarImage})` }"
        />
        <span class="px-3 text-truncate">
          {{ user.name }}
        </span>
      </v-chip>

      <!-- Avatar Only -->
      <v-avatar
        v-else
        class="avatar"
        :size="size"
        :style="{ backgroundImage: `url(${avatarImage})` }"
        v-on="!!user.email ? on : undefined"
      />
    </template>

    <!-- Menu Actions -->
    <v-list v-if="user.active">
      <v-list-item @click="viewUser">
        View {{ user.name }}
      </v-list-item>
      <v-list-item @click="editUser">
        Edit {{ user.name }}
      </v-list-item>
      <v-list-item @click="messageUser">
        Message {{ user.name }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { config } from '@/utils';
import emptyAvatar from '@/assets/img/no-avatar-icon.svg';

export default {
  props: {
    /**
     * Renders the avatar as a chip.
     *
     * @see https://v15.vuetifyjs.com/en/components/chips
     *
     * @type {boolean}
     */
    chip: {
      default: false,
      type: Boolean
    },

    /**
     * Sets the color of the chip.
     *
     * Only applies if `chip` is set to `true`.
     *
     * @type {string}
     */
    color: {
      default: undefined,
      type: String
    },

    /**
     * Renders the avatar chip as an outline.
     *
     * Only applies if `chip` is set to `true`.
     *
     * @type {boolean}
     */
    outlined: {
      default: false,
      type: Boolean
    },

    /**
     * Sets the size of the avatar.
     *
     * @type {number|string}
     */
    size: {
      default: 48,
      type: [Number, String]
    },

    /**
     * Sets the text color of the chip.
     *
     * Only applies if `chip` is set to `true`.
     *
     * @type {string}
     */
    textColor: {
      default: undefined,
      type: String
    },

    /**
     * Sets the user that is displayed in the avatar.
     *
     * @type {Object}
     */
    user: {
      type: Object,
      required: true
    }
  },

  computed: {
    /**
     * Returns the user's photo or an empty avatar image.
     *
     * @returns {string}
     */
    avatarImage() {
      return this.user.photo || emptyAvatar;
    }
  },

  methods: {
    /**
     * Navigates to the user's profile page in the portal application.
     */
    viewUser() {
      window.open(`${config.portalUrl}/#/user/${this.user.id}`, '_blank');
    },

    /**
     * Navigates to the user's edit page in the admin application.
     */
    editUser() {
      this.$router.push({ name: 'AdminManageEditUser', params: { id: this.user.id } });
    },

    /**
     * Calls a `mailto`-link to the users email address.
     */
    messageUser() {
      window.open(`mailto:${this.user.email}`, '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  cursor: pointer;
  background: center / cover no-repeat;
}

.chip-with-text {
  min-width: 165px;
  overflow: unset;

  .avatar {
    min-width: 32px !important;
  }
}
</style>

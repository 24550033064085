<template>
  <base-input
    :restore-message="computedRestorationMessage"
    :tooltip="tooltip"
    @restore="restoreOriginal"
  >
    <v-select
      v-if="onlyExisting"
      v-bind="$attrs"
      :counter="computedCounter"
      :label="computedLabel"
      :rules="computedRules"
      :item-text="itemText"
      :item-value="itemValue"
      @input="emitChanges"
    />

    <v-combobox
      v-else
      v-bind="$attrs"
      :allow-overflow="false"
      :counter="computedCounter"
      :label="computedLabel"
      :rules="computedRules"
      :item-text="itemText"
      :item-value="itemValue"
      @input="emitChanges"
    />
  </base-input>
</template>

<script>
import BaseInput from './_BaseInput';
import InputMixin from './_InputMixin';

export default {
  components: { BaseInput },

  mixins: [InputMixin],

  props: {
    /**
     * Sets the selection field to only allow existing values and not add new ones.
     *
     * If set to `true`, a `v-autocomplete` is rendered instead of a `v-combobox`.
     *
     * @type {boolean}
     */
    onlyExisting: {
      default: false,
      type: Boolean
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    itemText: {
      type: String,
      default: 'text'
    }
  },

  methods: {
    /**
     * Emits the changes to the selection field value.
     *
     * If the selection field is set to `multiple`, the resulting value array is sorted.
     * If the value to emit is `null`, `undefined` is emitted instead.
     *
     * @param {string|string[]} event The new value(s).
     */
    emitChanges(event) {
      if (this.$attrs.multiple) this.$emit('input', event.sort());
      else this.$emit('input', event === null ? undefined : event);
    }
  }
};
</script>

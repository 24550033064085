<template>
  <v-row

    justify="center"
  >
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-container class="tag-container">
          <v-card-title class="px-0">
            <span class="title">{{ title }}</span>
          </v-card-title>
          <editor
            :content="message"
            :show-cancel-button="true"
            submit-button-text="Send"
            toolbar="contentToolbar"
            @submit="send"
            @cancel="closeDialog"
            @keyUp="message = $event"
          />
          <v-card-text class="ma-0 pa-0">
            <span class="caption">
              {{ detailMessage }}
            </span>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ContentService, Editor } from '@kickbox/common-admin';
import DEFAULT_CONTENT from '@kickbox/common-util/constants/default-content';

export default {
  components: {
    Editor,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    userLanguage: {
      type: String,
      default: 'EN'
    },
    title: {
      type: String,
      default: ''
    },
    detailMessage: {
      type: String,
      default: ''
    },
    contentPage: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      message: ''
    };
  },
  created() {
    const company = this.$store.getters.company.parseObject;
    if (company) {
      ContentService.getContent(
        DEFAULT_CONTENT.PAGES[this.contentPage].key, company, this.userLanguage
      )
        .then((content) => {
          this.message = content ? content.text : '';
        });
    }
  },
  methods: {
    closeDialog() {
      this.message = '';
      this.$emit('close-dialog');
    },
    send() {
      this.$emit('send-message', this.message);
      this.closeDialog();
    }
  }
};
</script>

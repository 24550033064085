<template>
  <base-input
    :restore-message="computedRestorationMessage"
    :tooltip="tooltip"
    @restore="restoreOriginal"
  >
    <v-text-field
      v-bind="$attrs"
      :counter="computedCounter"
      :label="computedLabel"
      :rules="computedRules"
      @input="$emit('input', $event)"
    />
  </base-input>
</template>

<script>
import BaseInput from './_BaseInput';
import InputMixin from './_InputMixin';

export default {
  components: { BaseInput },

  mixins: [InputMixin]
};
</script>

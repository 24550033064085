<template>
  <v-container fluid>
    <v-card class="email-communication-container content-card-padding">
      <div class="d-flex">
        <div class="pa-4 first-col">
          <v-select
            v-model="selectedCampaign"
            class="select-campaign"
            :items="campaigns"
            :item-disabled="''"
            :item-value="'id'"
            :item-text="'title'"
            label="Select campaign"
            :disabled="!campaigns || !campaigns.length"
            solo
            @change="campaignSelectionChange"
          />
          <p class="title">
            Filters
          </p>
          <p class="subtitle-1 mt-4 mb-0">
            Roles
          </p>
          <v-checkbox
            v-for="r in roles"
            :key="r.label"
            v-model="r.selected"
            :disabled="loading || !selectedCampaign"
            :label="r.label"
            color="primary"
            class="mt-1"
            hide-details
            @change="toggleSelection('roles', roles, r)"
          />
          <p class="subtitle-1 mt-8 mb-0">
            Units
          </p>
          <v-autocomplete
            v-model="selectedUnits"
            class="my-0 py-1"
            clearable
            multiple
            :disabled="loading"
            :items="units"
            item-text="name"
            item-value="id"
            no-data-text="No units available"
            placeholder="Select an unit..."
            return-object
            deletable-chips
            small-chips
            @change="runFilters"
          />
          <p class="subtitle-1 mt-4 mb-1">
            Language
          </p>
          <v-checkbox
            v-for="lang in languages"
            :key="lang.value"
            v-model="lang.selected"
            :disabled="loading || !selectedCampaign"
            :label="lang.label"
            color="primary"
            class="mt-1"
            hide-details
            @change="toggleSelection('languages', languages, lang)"
          />
        </div>
        <v-divider vertical />
        <div class="second-col pa-4 flex-grow-0">
          <p class="subtitle-1">
            Recipients ({{ numberOfSelectedUsers }} / {{ recipients.length }})
          </p>
          <v-checkbox
            v-model="allRecipientSelected"
            :disabled="!recipients || !recipients.length"
            class="toggle-selection-all"
            label="Select all"
            color="primary"
            @change="selectAllRecipients"
          />
          <v-text-field
            v-model="searchRecipients"
            label="Search"
            clearable
          />
          <v-data-table
            :items="recipients"
            :headers="headers"
            :search="searchRecipients"
            :custom-filter="customFilter"
            :loading="loading"
            no-data-text=""
            hide-default-header
            hide-default-footer
            :options.sync="pagination"
            class="recipients-container"
          >
            <template #item="{ item }">
              <tr
                :active="item.selected"
                @click="selectUser(item)"
              >
                <td class="checkbox">
                  <v-checkbox
                    :input-value="item.selected"
                    primary
                    hide-details
                    color="primary"
                    class="ma-0"
                  />
                </td>
                <td class="details">
                  <p class="ma-0">
                    {{ item.name }}
                  </p>
                  <p class="ma-0 caption">
                    {{ item.email }}
                  </p>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-center pt-2 pagination-container">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
              :total-visible="6"
            />
          </div>
        </div>
        <v-divider vertical />
        <div class="pa-4 third-column flex-grow-1">
          <p class="subtitle-1">
            Send an email to {{ numberOfSelectedUsers }} users
          </p>
          <v-chip
            v-for="chip in chips"
            :key="chip"
            color="primary"
            text-color="white"
            class="font-weight-medium ml-2"
            label
          >
            {{ chip }}
          </v-chip>
          <v-text-field
            ref="subject"
            v-model="email.subject"
            label="Subject*"
            clearable
            placeholder="Enter a subject"
            class="mt-6"
            required
          />
          <label class="content-label">Content*</label>
          <editor
            submit-button-text="Send"
            :submit-button-disabled="disabledSendButton"
            :editor-variable-slot="editorVariables"
            @submit="showConfirmationDialog = true"
            @keyUp="(text) => email.body = text"
          />
          <div class="mt-1">
            <label class="body-1">* Mandatory field</label>
          </div>
          <emailPreview
            :show-send-preview="true"
            :send-button-disabled="disabledSendButton"
            :pre-fill-email="adminEmail"
            @send-preview="sendEmailPreview"
          />
        </div>
      </div>
    </v-card>
    <confirm-email-dialog
      v-model="showConfirmationDialog"
      :user-count="numberOfSelectedUsers"
      :affected-languages="selectedLanguages"
      @cancel="showConfirmationDialog = false"
      @send="sendEmail"
    />
  </v-container>
</template>

<script>
import {
  Editor, EmailPreview, EmailService, CompanyService
} from '@kickbox/common-admin';
import { mapGetters } from 'vuex';
import { USERNAME, COMPANY_NAME, USER_UNIT } from '@kickbox/common-util/constants/editor-variables';
import campaignService from '@/services/campaignService';
import userService from '@/services/userService';
import { ConfirmEmailDialog } from '../dialogs';

export default {
  name: 'CampaignsEmailCommunication',
  components: {
    ConfirmEmailDialog,
    Editor,
    EmailPreview
  },
  data() {
    return {
      loading: false,
      selectedCampaign: null,
      campaignMembers: null,
      // ===================================================================
      // Filter by options
      // ===================================================================
      roles: [
        { value: 'all', label: 'All', selected: true },
        { value: 'juries', label: 'Juries', selected: false },
        { value: 'kickboxers', label: 'KickBoxers', selected: false },
        { value: 'teamMembers', label: 'Team Members', selected: false },
      ],
      languages: [],
      units: [],
      selectedUnits: [],
      // ===================================================================
      // Table props
      // ===================================================================
      recipients: [],
      allRecipientSelected: true,
      selectedRecipients: {},
      numberOfSelectedUsers: 0,
      searchRecipients: null,
      pagination: {
        rowsPerPage: 25,
        totalPages: 10,
        page: 1
      },
      // ===================================================================
      // Email editor options
      // ===================================================================
      chips: [],
      headers: [
        {
          value: 'name',
        },
        {
          value: 'email',
        },
      ],
      email: {
        subject: '',
        body: ''
      },
      adminEmail: null,
      sendingInProgress: false,
      showConfirmationDialog: false,
      selectedLanguages: ['ALL'],
      editorVariables: [USERNAME, COMPANY_NAME, USER_UNIT]
    };
  },
  computed: {
    ...mapGetters([
      'company',
      'users',
      'campaigns'
    ]),
    pages() {
      if (!this.recipients || !this.recipients.length) {
        return 0;
      }
      return Math.ceil(this.recipients.length / this.pagination.rowsPerPage);
    },
    disabledSendButton() {
      return this.sendingInProgress || !this.email.subject.length || !this.email.body.length;
    },
  },
  async created() {
    this.getLanguageOptions();
    this.adminEmail = userService.getCurrentUserEmail();
    this.units = await CompanyService.getUnitsByCompany(this.company.parseObject) || [];
  },
  methods: {
    getLanguageOptions() {
      const companyLanguages = this.company.enabledLanguages;
      this.languages = [
        { value: 'all', label: 'All', selected: true },
        ...companyLanguages.map((_) => ({ value: _, label: _, selected: false }))
      ];
    },
    async campaignSelectionChange() {
      this.campaignMembers = await campaignService.fetchCampaignMembers(this.selectedCampaign);
      this.runFilters();
      this.countSelectedRecipients();
    },
    runFilters() {
      this.chips = [];
      let recipients = this.getRecipientsByLanguage(this.getRecipientsByType());
      if (this.selectedUnits.length) {
        recipients = recipients.filter(
          (user) => this.selectedUnits.some((unit) => user.get('unit') && user.get('unit').id === unit.id)
        );
        this.selectedUnits.forEach((u) => this.chips.push(u.name));
      }
      this.recipients = this.parseUsersForTable(recipients);
      this.countSelectedRecipients();
    },
    getRecipientsByType() {
      // ===================================================================
      // Filter users by type - Juries - Kickboxers - Experts
      // ===================================================================
      let recipients = [];
      const allSelected = this.roles.filter((role) => role.selected);
      if (!allSelected.length || (allSelected[0].selected && allSelected[0].value === 'all')) {
        recipients = Object.values(this.campaignMembers).reduce((acc, curr) => {
          acc.push(...curr);
          return acc;
        }, []);
      } else {
        allSelected.forEach((r) => {
          this.chips.push(r.label);
          recipients.push(...(this.campaignMembers[r.value] || []));
        });
      }
      return recipients;
    },
    getRecipientsByLanguage(source) {
      // ===================================================================
      // Filter users by languages
      // ===================================================================
      const allSelected = this.languages.filter((lang) => lang.selected);
      this.selectedLanguages = allSelected.map((lang) => lang.label);
      if (!allSelected.length || (allSelected[0].selected && allSelected[0].value === 'all')) {
        return source;
      }
      const lang = allSelected.map((_) => {
        this.chips.push(_.label);
        return _.value;
      });
      return source.filter((r) => lang.indexOf(r.get('preferredLanguage')) >= 0);
    },
    parseUsersForTable(source) {
      return source.map((user) => {
        const { id } = user;
        if (!this.selectedRecipients.hasOwnProperty(id)) {
          this.selectedRecipients[id] = true;
        }
        return {
          id,
          email: user.get('username'),
          name: user.get('name'),
          lang: user.get('preferredLanguage'),
          selected: !!this.selectedRecipients[id]
        };
      });
    },
    selectAllRecipients(event) {
      this.recipients.forEach((r) => {
        // If I dont ignore this, and use a temporary variable,
        // ESLint complains variable is redundant
        // eslint-disable-next-line no-param-reassign
        r.selected = event;
      });
      this.countSelectedRecipients();
    },
    toggleSelection(context, source, role) {
      // ===================================================================
      // Event for selection for filter categories - User type / Language
      // ===================================================================
      const allSelectedOfType = role.value === 'all' && role.selected;
      if (allSelectedOfType) {
        source.forEach((item) => {
          const selectedItem = item;
          selectedItem.selected = selectedItem.value === 'all';
        });
      } else {
        const all = source.find((_) => _.value === 'all');
        all.selected = false;
      }
      this.runFilters();
    },
    selectUser(user) {
      // ===================================================================
      // Event for user check/unchecking from table
      // ===================================================================
      const selectedUser = user;
      selectedUser.selected = !selectedUser.selected;
      this.selectedRecipients[selectedUser.id] = selectedUser.selected;
      if (!selectedUser.selected) {
        this.allRecipientSelected = false;
      }
      this.countSelectedRecipients();
    },
    countSelectedRecipients() {
      // ===================================================================
      // Count the selected recipients. No need for watchers.
      // ===================================================================
      this.numberOfSelectedUsers = this.recipients.filter((r) => r.selected).length;
    },
    customFilter(value, search, item) {
      const customSearch = search.toString().toLowerCase();
      return item.email.toLowerCase().includes(customSearch)
          || item.name.toLowerCase().includes(customSearch);
    },
    mapLangEmail(users) {
      return users.reduce((acc, current) => {
        const userLanguage = current.language;
        if (!acc.hasOwnProperty(userLanguage)) {
          acc[userLanguage] = [];
        }
        acc[userLanguage].push(current.email);
        return acc;
      }, {});
    },
    async sendEmail() {
      this.showConfirmationDialog = false;
      this.sendingInProgress = true;
      const users = this.recipients.filter((r) => r.selected);
      const langEmailMap = this.mapLangEmail(users);

      if (!this.numberOfSelectedUsers) {
        this.$store.dispatch('showSnackBar', { text: 'Select at least 1 recipient.' });
        return;
      }
      const content = {
        subject: this.email.subject,
        text: this.email.body
      };
      const promises = Object.keys(langEmailMap).map((lang) => EmailService.sendEmailToUser(
        { content },
        langEmailMap[lang],
        lang
      ));
      await Promise.all(promises);
      this.$store.dispatch('showSnackBar', { text: 'Emails have been sent.' });
      this.sendingInProgress = false;
    },
    async sendEmailPreview(email) {
      this.sendingInProgress = true;
      const content = {
        subject: this.email.subject,
        text: this.email.body
      };
      await EmailService.sendPreviewEmail({ content }, email).then(() => {
        this.$store.dispatch('showSnackBar', { text: 'The email was sent.' });
      });
      this.sendingInProgress = false;
    }
  }
};
</script>

<style lang="scss">
  .email-communication-container {
    .toggle-selection-all {
      float: right;
      padding: 0 !important;
      margin: 0 !important;
    }

    .first-col {
      width: 500px;
      .select-campaign {
        max-width: 300px;
      }
    }

    .second-col {
      width: 360px;

      .recipients-container {
        overflow-y: scroll;
        max-height: calc(100vh - 340px);
        min-height: calc(100vh - 340px);
        width: 330px;
      }
    }
  }
</style>

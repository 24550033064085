<template>
  <base-project-dialog
    v-if="editableProject && projectId"
    :max-width="1000"
    :value="showModal"
    :persistent="hasPendingChanges"
    :project="project"
    @input="closeModal"
  >
    <!-- Phase Chip -->
    <v-chip
      slot="toolbar-chip"
      class="phase-chip"
      :color="project.isAlumni ? '#8d98a5' : project.phase.color"
      dark
    >
      <b>{{ project.isAlumni ? 'Alumni' : project.phase.title }}</b>
    </v-chip>

    <project-actions
      slot="header-actions"
      :project="project"
      :has-pending-changes="hasPendingChanges"
      @projectDeleted="closeModal"
      @projectMarkedAsAlumni="markAsAlumni"
      @projectUnmarkedAsAlumni="updateProjectStore"
      @showCreateCertificate="showCertificateDialog"
      @showChangeOwner="showChangeOwnerDialog = true"
    />

    <v-tabs
      slot="extension"
      v-model="tab"
      background-color="transparent"
      grow
      slider-color="blue"
    >
      <v-tab
        v-for="(item, i) in tabs"
        :key="i"
      >
        {{ item.title || item.key }}
      </v-tab>
    </v-tabs>

    <!-- Tab Items -->
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(item, i) in tabs"
        :key="i"
        class="dialog-content py-5 px-6"
        eager
      >
        <!-- Details -->
        <template v-if="item.key === 'details'">
          <!-- Title -->
          <text-field
            v-model="editableProject.title"
            autofocus
            clearable
            label="Title"
            :original="project.title"
            placeholder="Enter a title..."
            required
          />

          <!-- Tagline -->
          <text-field
            v-model="editableProject.tagline"
            class="mt-2"
            clearable
            :counter="[3, 60]"
            label="Tagline"
            :original="project.tagline"
            placeholder="Enter a tagline..."
            required
          />

          <!-- Description -->
          <label class="grey--text text--darken-1">Description *</label>
          <editor
            class="mt-1"
            :content="editableProject.description"
            :show-submit-button="false"
            :required="true"
            toolbar="minimalToolbar"
            @keyUp="editableProject.description = $event"
          />

          <!-- Tags -->
          <selection-field
            v-model="editableProject.tags"
            class="mt-2"
            clearable
            :counter="3"
            deletable-chips
            :items="activeTags"
            item-text="name"
            hide-selected
            label="Tags"
            multiple
            open-on-clear
            :original="project.tags"
            placeholder="Select tags..."
            required
            return-object
            small-chips
          />

          <v-row
            no-gutters
            class="two-columns"
          >
            <v-col
              cols="12"
            >
              <label class="caption grey--text">Creation Date</label>
              <p class="body-2 grey--text">
                {{ creationDate }}
              </p>
            </v-col>
            <!-- Creator Email -->
            <v-col cols="6">
              <text-field
                disabled
                label="Creator Email"
                :value="form.creator.email"
              />
            </v-col>

            <!-- Project Unit -->
            <v-col cols="6">
              <text-field
                disabled
                label="Project Unit"
                :value="form.projectUnit && form.projectUnit.name"
              />
            </v-col>

            <!-- Team Members -->
            <v-col
              v-if="hasFeature(features.TEAM_MEMBERS)"
              :cols="form.teamMembers.length ? 12 : 6"
            >
              <team-member-list
                :items="form.teamMembers"
              />
            </v-col>
            <!-- Empty div so that the padding is still correct -->
            <div v-if="form.teamMembers.length && hasFeature(features.TEAM_MEMBERS)" />

            <!-- Requested Starting Month -->
            <v-col
              v-if="isPending && hasFeature(features.REQUESTED_STARTING_MONTH)"
              cols="6"
            >
              <text-field
                disabled
                label="Requested Starting Month"
                :value="project.startMonth.format('MMMM YYYY')"
              />
            </v-col>

            <!-- Interested Unit -->
            <v-col
              v-if="!isPending"
              cols="6"
            >
              <selection-field
                v-model="editableProject.interestedUnit"
                clearable
                hint="optional"
                :items="units"
                item-text="name"
                label="Interested Unit"
                :menu-props="{ bottom: true, offsetY: true }"
                no-data-text="No units available"
                only-existing
                open-on-clear
                :original="project.interestedUnit"
                placeholder="Select an interested unit..."
                persistent-hint
                return-object
              />
            </v-col>

            <!-- Campaign -->
            <v-col
              v-if="hasFeature(features.CAMPAIGNS)"
              cols="6"
            >
              <selection-field
                v-model="editableProject.campaign"
                clearable
                hint="optional"
                :items="campaigns.filter(c => !c.disabled)"
                item-text="title"
                label="Campaign"
                :menu-props="{ bottom: true, offsetY: true }"
                no-data-text="No campaigns available"
                only-existing
                open-on-clear
                :original="project.campaign"
                placeholder="Select a campaign..."
                persistent-hint
                return-object
              />
            </v-col>

            <!-- Cross-Corporate -->
            <template v-if="hasFeature(features.CROSS_CORPORATE)">
              <v-col cols="6">
                <selection-field
                  v-model="editableProject.crossCorporate"
                  clearable
                  hint="optional"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  label="Cross-Corporate"
                  :menu-props="{ bottom: true, offsetY: true }"
                  multiple
                  no-data-text="No cross-corporate companies available"
                  only-existing
                  open-on-clear
                  :original="project.crossCorporate"
                  persistent-hint
                  placeholder="Select cross-corporate partners..."
                  return-object
                />
              </v-col>

              <!-- Cross-Corporate Tags -->
              <v-col
                v-if="editableProject.crossCorporate.length"
                cols="6"
              >
                <selection-field
                  v-model="editableProject.crossCorporateTags"
                  class="mt-2"
                  clearable
                  :counter="3"
                  deletable-chips
                  hide-selected
                  :items="globalTags"
                  item-text="name"
                  label="Cross-Corporate Tags"
                  :menu-props="{ bottom: true, offsetY: true }"
                  multiple
                  open-on-clear
                  :original="project.crossCorporateTags"
                  placeholder="Select cross-corporate tags..."
                  required
                  return-object
                  small-chips
                />
              </v-col>
            </template>

            <!-- Funding Goal -->
            <v-col
              v-if="!isPending && !isRedBoxIdea"
              cols="6"
            >
              <text-field
                v-model.number="editableProject.fundingGoal"
                clearable
                label="Funding Goal"
                :min="1"
                :original="project.fundingGoal"
                placeholder="Enter a funding goal..."
                required
                type="number"
              />
            </v-col>

            <!-- Change Coin Balance -->
            <v-col
              v-if="!isPending && hasFeature(features.COINS)"
              cols="6"
            >
              <text-field
                v-model.number="coinBalanceDelta"
                clearable
                :hint="`Current balance: ${ project.bankAccount.coinBalance }`"
                label="Add or Remove Coins"
                :min="-project.bankAccount.coinBalance"
                persistent-hint
                placeholder="Enter amount..."
                type="number"
              />
            </v-col>

            <!-- Extends Days -->
            <v-col
              v-if="isRedBoxIdea || isRedBoxFunding"
              cols="6"
            >
              <project-duration
                :phase="{
                  start: project.phase.startDate,
                  end: project.phase.endDate
                }"
                @value="phaseDuration = $event"
                @error="phaseDurationError = $event"
              />
            </v-col>
          </v-row>

          <edit-sponsors
            v-if="isBlueBox || isGoldBox"
            v-model="editableProject.sponsors"
          />
        </template>

        <!-- Media (Image & Videos) -->
        <template v-if="item.key === 'media'">
          <!-- Cover Image -->
          <select-image
            ref="selectImage"
            :initial-image="editableProject.image"
            :width="240"
            :height="135"
            :disabled="isSaving"
            required
            @change="onImageChange"
            @remove="hasImage = false"
          />
          <!-- Redbox Pitch -->
          <select-video
            ref="redboxPitch"
            v-model="editableProject.videos.redboxPitch"
            :disabled="isSaving"
            label="1 Minute Video"
            pitch="oneMinute"
            :project="project"
            :required="!isPending"
            @isUploadInProgress="uploadInProgress.redboxPitch = $event"
          />

          <!-- Redbox Funding Pitch -->
          <select-video
            v-if="!isRedBoxIdea && !isPending"
            ref="redboxFundingPitch"
            v-model="editableProject.videos.redboxFundingPitch"
            :disabled="isSaving"
            label="8 Minute Video"
            pitch="eightMinute"
            :project="project"
            required
            @isUploadInProgress="uploadInProgress.redboxFundingPitch = $event"
          />

          <!-- Bluebox Pitch -->
          <select-video
            v-if="isBlueBox || isGoldBox"
            ref="blueboxPitch"
            v-model="editableProject.videos.blueboxPitch"
            :disabled="isSaving"
            label="Bluebox Video"
            pitch="bluebox"
            :project="project"
            @isUploadInProgress="uploadInProgress.blueboxPitch = $event"
          />

          <!-- Goldbox Pitch -->
          <select-video
            v-if="isGoldBox"
            ref="goldboxPitch"
            v-model="editableProject.videos.goldboxPitch"
            :disabled="isSaving"
            label="Goldbox Video"
            pitch="goldbox"
            :project="project"
            @isUploadInProgress="uploadInProgress.goldboxPitch = $event"
          />
        </template>

        <!-- Coaching -->
        <template v-if="item.key === 'coaching'">
          <coaching-languages v-model="editableProject.coachingLanguages" />
          <select-users
            v-model="editableProject.coaches"
            :source="allCoaches"
            show-admin-role
          />
          <edit-email-campaign
            v-if="!isPending"
            v-model="editableProject.emailCampaign"
            :original="project.emailCampaign"
            :project="project"
            show-coaching-number
            @coachingNumber="editableProject.coachingNumber = $event"
          />
        </template>

        <!-- Approve or Reject -->
        <template v-if="item.key === 'approve'">
          <template v-if="!project.isApproved">
            <!-- Approval Message -->
            <label class="grey--text text--darken-1">Approval Message *</label>
            <editor
              class="mt-1"
              :content="approveProjectMessage"
              :show-submit-button="false"
              toolbar="contentToolbar"
              @keyUp="approveProjectMessage = $event"
            />
            <label>This message will be sent to the project creator and the team members.</label>

            <!-- Start Project Checkbox -->
            <v-checkbox
              v-model="startProject.intent"
              hide-details
              label="Start the Project"
            />
            <label>Starting the project will move it to the Redbox phase.</label>
          </template>

          <v-expand-transition>
            <div v-show="project.isApproved || startProject.intent">
              <v-divider
                v-if="!project.isApproved"
                class="my-2"
              />
              <!-- Email Campaign -->
              <edit-email-campaign
                v-model="startProject.emailCampaign"
                :check-by-default="true"
              />
              <!-- Coins Input -->
              <text-field
                v-if="hasFeature(features.COINS)"
                v-model.number="startProject.coins"
                clearable
                :disabled="isSaving"
                label="Coins"
                :min="0"
                :original="1000"
                :class="!!startProject.emailCampaign ? 'mt-0' : 'mt-4'"
                placeholder="Enter the project coins..."
                required
                type="number"
              />

              <!-- Redbox Pitch -->
              <label
                :class="{
                  'd-flex': true,
                  'error--text': !editableProject.videos.redboxPitch,
                  'grey--text': !!editableProject.videos.redboxPitch,
                  'mb-2': true,
                  'text--darken-1': !!editableProject.videos.redboxPitch
                }"
              >
                1 Minute Video *
              </label>
              <app-button
                v-if="!editableProject.videos.redboxPitch"
                class="mb-6"
                secondary
                @click="switchTab('media')"
              >
                Upload Video
              </app-button>
              <video-display
                v-else
                class="mb-2"
                edit-mode
                :storage-key="videoStorageKey('oneMinute')"
                :url="editableProject.videos.redboxPitch"
              />
            </div>
          </v-expand-transition>
        </template>

        <!-- Unapprove -->
        <template v-if="item.key === 'unapprove'">
          <label class="grey--text text--darken-1">Unapprove Message *</label>
          <editor
            class="mt-1"
            :content="unapproveProjectMessage"
            :show-submit-button="false"
            toolbar="contentToolbar"
            @keyUp="unapproveProjectMessage = $event"
          />
          <label>This message will be sent to the project creator and the team members.</label>
        </template>

        <!-- Change Phase -->
        <template v-if="item.key === 'move'">
          <v-select
            v-model="movableProject.phase.name"
            :items="availablePhases"
            label="Target Phase"
            item-value="name"
          />
          <text-field
            v-if="isRedBoxIdea"
            v-model.number="movableProject.fundingGoal"
            clearable
            label="Funding Goal"
            placeholder="Enter a funding goal..."
            required
            type="number"
            :min="1"
          />
          <!-- Redbox Funding Pitch -->
          <select-video
            v-if="isRedBoxIdea"
            ref="redboxFundingPitch"
            v-model="movableProject.videos.redboxFundingPitch"
            :disabled="isSaving"
            label="8 Minute Video"
            pitch="eightMinute"
            :project="project"
            required
            @isUploadInProgress="uploadInProgress.redboxFundingPitch = $event"
          />

          <edit-sponsors
            v-if="isMovableBlueBox"
            v-model="movableProject.sponsors"
          />

          <!-- Bluebox Pitch -->
          <select-video
            v-if="isMovableBlueBox"
            ref="blueboxPitch"
            v-model="movableProject.videos.blueboxPitch"
            :disabled="isSaving"
            label="Bluebox Video"
            pitch="bluebox"
            :project="project"
            @isUploadInProgress="uploadInProgress.blueboxPitch = $event"
          />

          <!-- Goldbox Pitch -->
          <select-video
            v-if="isMovableGoldBox"
            ref="goldboxPitch"
            v-model="movableProject.videos.goldboxPitch"
            :disabled="isSaving"
            label="Goldbox Video"
            pitch="goldbox"
            :project="project"
            @isUploadInProgress="uploadInProgress.goldboxPitch = $event"
          />
        </template>

        <!-- Learnings -->
        <template v-if="item.key === 'learnings'">
          <!-- Learnings Pitch -->
          <select-video
            ref="learningsVideo"
            v-model="editableProject.videos.learningsVideo"
            :disabled="isSaving"
            label="Learnings Video"
            pitch="learnings"
            :project="project"
            @isUploadInProgress="uploadInProgress.learningsVideo = $event"
          />
          <v-text-field
            v-model="editableProject.learningsUrl"
            class="mb-2"
            clearable
            :disabled="isSaving"
            label="Link to learnings document"
            placeholder="Add a link to a learnings document e.g: OneDrive, SharePoint, Dropbox, etc"
          />
          <label class="grey--text text--darken-1">Additional comments</label>
          <editor
            class="my-4"
            :content="editableProject.learningsDescription"
            :disabled="isSaving"
            :show-submit-button="false"
            :required="true"
            toolbar="minimalToolbar"
            @keyUp="editableProject.learningsDescription = $event"
          />
        </template>
      </v-tab-item>
    </v-tabs-items>

    <v-divider />

    <v-card-actions slot="actions">
      <app-button
        cancel
        secondary
        @click="closeModal"
      >
        {{ hasPendingChanges ? 'Cancel' : 'Close' }}
      </app-button>

      <v-spacer />

      <template v-if="selectedTab === 'approve'">
        <!-- Reject Button -->
        <v-tooltip
          v-if="!project.isApproved"
          :disabled="isValid && !rejectApproveDisabled"
          top
        >
          <template #activator="{ on }">
            <div
              class="mr-4"
              v-on="on"
            >
              <app-button
                cancel
                :disabled="!isValid || rejectApproveDisabled || isUploadInProgress"
                :loading="hasRejectIntention"
                secondary
                @click="showRejectDialog = true"
              >
                Reject
              </app-button>
            </div>
          </template>
          <span>
            <template v-if="!isValid">
              Please fix the following input values:
              <ul>
                <li
                  v-for="(field, index) of invalidEditableFields"
                  :key="index"
                >
                  {{ field }}
                </li>
              </ul>
            </template>

            <template v-if="rejectApproveDisabled">
              You're not a coach of this project!
            </template>
          </span>
        </v-tooltip>

        <!-- Approve Button -->
        <v-tooltip
          :disabled="!invalidApproveFields.length && !rejectApproveDisabled"
          top
        >
          <template #activator="{ on }">
            <div v-on="on">
              <app-button
                :disabled="
                  isUploadInProgress || !!invalidApproveFields.length || rejectApproveDisabled
                "
                :loading="hasApproveIntention"
                @click="managePendingProjectStatus(true)"
              >
                {{ approveButtonText }}
              </app-button>
            </div>
          </template>
          <span>
            <template v-if="invalidApproveFields.length">
              Please fix the following input values:
              <ul>
                <li
                  v-for="(field, index) of invalidApproveFields"
                  :key="index"
                >
                  {{ field }}
                </li>
              </ul>
            </template>

            <template v-if="rejectApproveDisabled">
              You're not a coach of this project!
            </template>
          </span>
        </v-tooltip>
      </template>

      <template v-else-if="selectedTab === 'unapprove'">
        <!-- Unapprove Button -->
        <v-tooltip
          :disabled="!invalidApproveFields.length && !rejectApproveDisabled"
          top
        >
          <template #activator="{ on }">
            <div v-on="on">
              <app-button
                :disabled="
                  isUploadInProgress || !!invalidApproveFields.length || rejectApproveDisabled
                "
                :loading="hasApproveIntention"
                color="error"
                @click="managePendingProjectStatus(false)"
              >
                Unapprove
              </app-button>
            </div>
          </template>
          <span>
            <template v-if="invalidApproveFields.length">
              Please fix the following input values:
              <ul>
                <li
                  v-for="(field, index) of invalidApproveFields"
                  :key="index"
                >
                  {{ field }}
                </li>
              </ul>
            </template>

            <template v-if="rejectApproveDisabled">
              You're not a coach of this project!
            </template>
          </span>
        </v-tooltip>
      </template>

      <template v-else>
        <v-tooltip
          :disabled="isValid"
          top
        >
          <template #activator="{ on }">
            <div v-on="on">
              <app-button
                v-if="selectedTab === 'move'"
                :disabled="!isValid || isUploadInProgress"
                :loading="isSaving"
                @click="showChangePhaseDialog = true"
              >
                Enter {{ selectedPhaseTitle }}
              </app-button>
              <app-button
                v-else
                :disabled="!hasPendingChanges || !isValid || isUploadInProgress"
                :loading="isSaving"
                @click="saveChanges"
              >
                Save Changes
              </app-button>
            </div>
          </template>
          <span>
            <template v-if="!isValid">
              Please fix the following input values:
              <ul>
                <li
                  v-for="(field, index) of invalidEditableFields"
                  :key="index"
                >
                  {{ field }}
                </li>
              </ul>
            </template>
          </span>
        </v-tooltip>
      </template>
    </v-card-actions>
    <base-content-dialog
      v-if="showRejectDialog"
      :show-dialog="showRejectDialog"
      :user-language="project.creator.language"
      title="Rejection Project Message"
      detail-message="*This message will be added to the rejection email
       and will show up under the coaching notes section for the admins."
      content-page="PROJECT_REJECT_MESSAGE"
      @close-dialog="showRejectDialog = false"
      @send-message="rejectPendingProject"
    />
    <confirm-dialog
      :show="showChangePhaseDialog"
      :confirm-click="saveChanges"
      @close="showChangePhaseDialog = false"
    >
      <div v-if="goNextPhase">
        Are you sure you want to change
        the phase to "{{ selectedPhaseTitle }}"?
      </div>
      <div v-else>
        <div>Are you sure you want to go back to the phase "{{ selectedPhaseTitle }}"?</div>
        <div class="subtitle-1 my-2">
          The data added in the current phase will not be lost.
          It will appear once the project is moved again to the current phase.
        </div>
      </div>
    </confirm-dialog>
    <certificate-dialog />
    <change-owner
      v-if="showChangeOwnerDialog"
      :show="showChangeOwnerDialog"
      :project="project"
      @confirm="ownerChanged"
      @close="showChangeOwnerDialog = false"
    />
  </base-project-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import Moment from 'moment';

import {
  ConfirmDialog, ContentService, Editor, SelectImage, SelectUsers
} from '@kickbox/common-admin';
import { VideoDisplay } from '@kickbox/common-components';
import { PENDING, REDBOX_IDEA, REDBOX_FUNDING, BLUEBOX, GOLDBOX, PHASE_ORDER } from '@kickbox/common-util';
import DEFAULT_CONTENT from '@kickbox/common-util/constants/default-content';
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import fileService from '@kickbox/common-util/service/fileService';
import videoMixin from '@kickbox/common-util/src/mixins/video';
import { SelectionField, TextField } from '@/components/formComponents';
import EditEmailCampaign from '@/components/projects/EditEmailCampaign';
import EditSponsors from '@/components/projects/EditSponsors';
import ProjectActions from '@/components/projects/ProjectActions';
import ProjectDuration from '@/components/projects/ProjectDuration';
import BaseContentDialog from '@/components/projects/BaseContentDialog';
import SelectVideo from '@/components/widgets/SelectVideo';
import TeamMemberList from '@/components/projects/TeamMemberList';
import { phaseService, translationService, projectService, roleService, sponsorService, userService } from '@/services';
import ProjectModalMixin from './_ProjectModalMixin';
import BaseProjectDialog from './_BaseProjectDialog';
import CoachingLanguages from '@/components/projects/CoachingLanguages';
import CertificateDialog from '@/components/projects/CertificateDialog';
import ChangeOwner from '@/components/projects/ChangeOwner';

export default {
  components: {
    SelectUsers,
    BaseContentDialog,
    SelectionField,
    TextField,
    VideoDisplay,
    SelectVideo,
    ConfirmDialog,
    BaseProjectDialog,
    EditEmailCampaign,
    EditSponsors,
    ProjectDuration,
    ProjectActions,
    TeamMemberList,
    SelectImage,
    Editor,
    CoachingLanguages,
    CertificateDialog,
    ChangeOwner
  },
  mixins: [ProjectModalMixin],
  data() {
    return {
      editableProject: undefined,
      movableProject: undefined,
      isSaving: false,
      hasRejectIntention: false,
      hasApproveIntention: false,
      tab: 0,
      showApproveDialog: false,
      showRejectDialog: false,
      showChangePhaseDialog: false,
      showChangeOwnerDialog: false,
      features: FEATURE_NAME,
      uploadInProgress: {
        redboxPitch: false,
        redboxFundingPitch: false,
        blueboxPitch: false,
        goldboxPitch: false,
        learningsVideo: false
      },
      coinBalanceDelta: null,
      phaseDuration: null,
      phaseDurationError: false,
      rejectApproveDisabled: false,

      approveProjectMessage: null,
      unapproveProjectMessage: null,
      startProject: {
        intent: false,
        coins: 1000,
        emailCampaign: null
      },
      imageHasChange: false,
      hasImage: false,
      form: {
        creator: {},
        teamMembers: [],
        projectUnit: {
          name: 'no unit'
        }
      }
    };
  },
  computed: {
    ...mapGetters(['campaigns', 'companies', 'company', 'projectById', 'units', 'phases', 'activeTags', 'globalTags', 'allCoaches']),
    isValid() {
      return this.invalidEditableFields.length === 0;
    },
    isUploadInProgress() {
      return this.uploadInProgress.redboxPitch
        || this.uploadInProgress.redboxFundingPitch
        || this.uploadInProgress.blueboxPitch
        || this.uploadInProgress.goldboxPitch
        || this.uploadInProgress.learningsVideo;
    },
    isPending() {
      return this.project.phase.name === PENDING.name;
    },
    isRedBoxIdea() {
      return this.project.phase.name === REDBOX_IDEA.name;
    },
    isRedBoxFunding() {
      return this.project.phase.name === REDBOX_FUNDING.name;
    },
    isBlueBox() {
      return this.project.phase.name === BLUEBOX.name;
    },
    isGoldBox() {
      return this.project.phase.name === GOLDBOX.name;
    },
    isMovableBlueBox() {
      return (this.movableProject.phase.name === BLUEBOX.name
        || (this.isMovableGoldBox && !this.isBlueBox))
          && this.goNextPhase;
    },
    isMovableGoldBox() {
      return this.movableProject.phase.name === GOLDBOX.name && this.goNextPhase;
    },
    hasPendingChanges() {
      return !this.$lodash.isEqual(this.editableProject, this.project)
        || this.imageHasChange
        || this.phaseDuration > 0
        || this.phaseDuration < 0
        || this.coinBalanceDelta > 0
        || this.coinBalanceDelta < 0;
    },
    selectedPhaseTitle() {
      const selectedPhase = this.phases.find((p) => p.name === this.movableProject.phase.name);
      return selectedPhase ? selectedPhase.text : '';
    },
    selectedTab() {
      return this.tabs[this.tab] ? this.tabs[this.tab].key : 0;
    },
    /**
     * Returns the names of the input fields with invalid values.
     *
     * @returns {string[]}
     */
    invalidEditableFields() {
      if (!this.editableProject) {
        return [];
      }
      const invalidFields = [];

      // Validate title
      if (!this.validateValueWithCounter(this.editableProject.title, false, 0, 3)) {
        invalidFields.push('Title');
      }

      // Validate tagline
      if (!this.validateValueWithCounter(this.editableProject.tagline, false, 60, 1)) {
        invalidFields.push('Tagline');
      }

      // Validate description
      if (!this.validateValueWithCounter(this.editableProject.description, false, 0, 1)) {
        invalidFields.push('Description');
      }

      // Validate tags
      if (!this.validateValueWithCounter(this.editableProject.tags, false, 3, 1)) {
        invalidFields.push('Tags');
      }

      // Validate cross-corporate tags
      if (this.editableProject.crossCorporate.length
        && !this.validateValueWithCounter(this.editableProject.crossCorporateTags, false, 3, 1)) {
        invalidFields.push('Cross-Corporate Tags');
      }

      if (!this.isPending
        && !this.isRedBoxIdea
        && (this.editableProject.fundingGoal === '' || this.editableProject.fundingGoal < 1)) {
        invalidFields.push('Funding Goal');
      }

      if (this.phaseDurationError) {
        invalidFields.push('Phase Duration');
      }

      if (this.coinBalanceDelta < -this.project.bankAccount.coinBalance) {
        invalidFields.push('Coins');
      }

      if ((this.isBlueBox || this.isGoldBox) && (!this.editableProject.sponsors.length
        || this.editableProject.sponsors
          .some((sponsor) => !sponsor.name.length || !sponsor.amount > 0 || !sponsor.unit))) {
        invalidFields.push('Sponsors');
      }

      if (!this.hasImage) {
        invalidFields.push('Image');
      }

      if (!this.isPending && !this.editableProject.videos.redboxPitch) {
        invalidFields.push('1 Minute Video');
      }

      if (!this.isPending && !this.isRedBoxIdea
          && !this.editableProject.videos.redboxFundingPitch) {
        invalidFields.push('8 Minute Video');
      }

      if (this.selectedTab === 'move') {
        if (this.isRedBoxIdea
          && (this.movableProject.fundingGoal === ''
            || this.movableProject.fundingGoal <= 0)) {
          invalidFields.push('Funding Goal');
        }
        if (this.isRedBoxIdea && !this.movableProject.videos.redboxFundingPitch) {
          invalidFields.push('8 Minute Video');
        }
        if (this.isMovableBlueBox && (!this.movableProject.sponsors.length
          || this.movableProject.sponsors
            .some((sponsor) => !sponsor.name.length || !sponsor.amount > 0 || !sponsor.unit))) {
          invalidFields.push('Sponsors');
        }
      }

      return invalidFields;
    },
    /**
     * Returns the names of the input fields with invalid values for the approve case.
     *
     * @returns {string[]}
     */
    invalidApproveFields() {
      const invalidFields = [...this.invalidEditableFields];

      // Approval Message
      if (!this.project.isApproved && !this.approveProjectMessage) {
        invalidFields.push('Approval Message');
      }

      if (this.project.isApproved || this.startProject.intent) {
        // Redbox Pitch
        if (!this.editableProject.videos.redboxPitch && this.selectedTab !== 'unapprove') {
          invalidFields.push('1 Minute Video');
        }

        // Coins
        if (
          this.hasFeature(FEATURE_NAME.COINS)
          && (this.startProject.coins === null || Number(this.startProject.coins) < 0)
        ) {
          invalidFields.push('Coins');
        }
      }

      return invalidFields;
    },

    /**
     * Builds the array of tabs that should be displayed on the dialog.
     *
     * @returns {object[]}
     */
    tabs() {
      const tabs = [
        { key: 'details' },
        { key: 'media', title: 'Image & Videos' },
        { key: 'coaching' }
      ];
      if (this.project.phase.name === PENDING.name && this.project.creator.active) {
        tabs.push({
          key: 'approve',
          title: this.project.isApproved ? 'Start Project' : 'Approve & Reject'
        });
      }
      if (this.project.phase.name === PENDING.name && this.project.creator.active
          && this.project.isApproved) {
        tabs.push({
          key: 'unapprove',
          title: 'Unapprove Project'
        });
      }
      if (
        ![PENDING.name].includes(this.project.phase.name)
        && !this.project.isAlumni
      ) {
        tabs.push({ key: 'move', title: 'Change Phase' });
      }
      if (this.project.isAlumni) {
        tabs.push({
          key: 'learnings',
          title: 'Learnings'
        });
      }
      return tabs;
    },
    videoStorageKey() {
      return (name) => `videos/project-${this.project.id}-${name}`;
    },
    availablePhases() {
      return this.phases.filter((phase) => phase.name !== this.project.phase.name);
    },
    createdSponsors() {
      return this.editableProject.sponsors.filter((s) => !s.id);
    },
    updatedSponsors() {
      return this.$lodash.differenceWith(
        this.editableProject.sponsors, this.project.sponsors, this.$lodash.isEqual
      ).filter((sponsor) => sponsor.id);
    },
    deletedSponsors() {
      return this.project.sponsors
        .filter((sponsor) => !this.editableProject.sponsors.find((s) => s.id === sponsor.id));
    },

    /**
     * Builds the Approve / Start button text.
     *
     * @returns {string}
     */
    approveButtonText() {
      const parts = [];

      if (!this.project.isApproved) parts.push('Approve');
      if (this.project.isApproved || this.startProject.intent) parts.push('Start Project');

      return parts.join(' & ');
    },
    photo() {
      return this.$refs.selectImage && this.$refs.selectImage[0]?.image;
    },
    goNextPhase() {
      const nextPhase = PHASE_ORDER[this.movableProject.phase.name];
      const currentPhase = PHASE_ORDER[this.project.phase.name];
      return nextPhase > currentPhase;
    },
    creationDate() {
      return new Moment(this.project.createdAt).format('MMM DD, YYYY');
    }
  },
  watch: {
    'editableProject.coaches': {
      deep: true,
      async handler(coaches) {
        if (coaches) {
          this.rejectApproveDisabled = await userService.canUserRejectOrApproveProject(
            this.editableProject.coaches,
            this.project.projectUnit.name
          );
        }
      }
    },
    'movableProject.phase.name': {
      deep: true,
      async handler(phase) {
        if (!this.movableProject.sponsors.length
            && (phase === BLUEBOX.name || phase === GOLDBOX.name)) {
          this.movableProject.sponsors = await projectService
            .getSponsorsForProject(this.project, true);
        }
      }
    }
  },
  methods: {
    /**
     * Creates and displays a new modal.
     *
     * @param {string}        projectId The ID of the target project.
     * @param {string|number} [tab=0]   The initially selected tab.
     */
    async openModal(projectId, tab = 0) {
      this.projectId = projectId;
      this.switchTab(tab);

      this.startProject = {
        intent: this.project.isApproved,
        coins: 1000
      };

      this.setMovableProject();

      if (this.projectId) {
        const sponsors = await projectService.getSponsorsForProject(this.project);
        this.$store.commit('updateDashboardProject', {
          ...this.project,
          sponsors
        });
        this.form.teamMembers = await projectService.getTeamMembersForProject(this.projectId);
      }

      this.editableProject = this.$lodash.cloneDeep(this.project);
      this.form.creator = this.editableProject.creator;
      this.form.projectUnit = this.editableProject.projectUnit;

      // Load image
      this.editableProject.image = this.project.photo;
      if (this.editableProject.image) {
        this.hasImage = true;
      }

      this.approveProjectMessage = await this.getContent('PROJECT_APPROVE_MESSAGE');
      this.unapproveProjectMessage = await this.getContent('PROJECT_UNAPPROVE_MESSAGE');

      this.$nextTick(() => {
        this.showModal = true;
      });
    },
    async getContent(contentPage) {
      const content = await ContentService.getContent(
        DEFAULT_CONTENT.PAGES[contentPage].key,
        this.company.parseObject,
        this.project.creator.language
      );
      return content?.text || null;
    },
    setMovableProject() {
      const currentPhase = this.phases.find((phase) => phase.name === this.project.phase.name);
      const index = this.phases.indexOf(currentPhase);
      this.movableProject = {
        videos: {
          redboxFundingPitch: this.project.videos.redboxFundingPitch || null,
          blueboxPitch: this.project.videos.blueboxPitch || null,
          goldboxPitch: this.project.videos.goldboxPitch || null
        },
        fundingGoal: this.project.fundingGoal || null,
        phase: { ...this.phases[index + 1] },
        sponsors: this.project.sponsors || []
      };
    },
    changePhase(projectId, phase) {
      if (phase.name === PENDING.name) {
        this.openModal(projectId, 4);
      } else {
        this.openModal(projectId, 3);
      }
      this.movableProject.phase = phase;

      if (phase.name === REDBOX_IDEA.name) this.startProject.intent = true;
    },
    async managePendingProjectStatus(isApproved) {
      if (this.isSaving) return;

      this.isSaving = true;
      this.hasApproveIntention = true;

      if (!isApproved) {
        this.startProject.intent = false;
      }

      try {
        // Set project to approved and save changes
        this.editableProject.isApproved = isApproved;
        await this.updateProject();

        if (!this.startProject.intent) {
          if (!this.project.isApproved) {
            projectService.sendEmailApprovedProject(this.project, this.approveProjectMessage);
          }
          if (this.project.isApproved) {
            projectService.sendUnapprovedProjectEmail(this.project, this.unapproveProjectMessage);
          }
        }

        // Start project
        if (this.startProject.intent) {
          await roleService.addKickBoxerRole(this.project.creator.email);
          await projectService.changeProjectPhase(REDBOX_IDEA.name, this.editableProject);

          // Only add coins if feature is enabled
          if (this.hasFeature(FEATURE_NAME.COINS)) {
            await projectService.addCreditToProject(
              this.project.bankAccount.id,
              this.startProject.coins
            );
          }

          await this.manageEmailCampaing(this.startProject.emailCampaign, 1);
        }
        let target;
        if (this.project.isApproved && this.selectedTab === 'unapprove') {
          target = 'unapproved';
        } else if (this.startProject.intent) {
          target = 'started';
        } else {
          target = 'approved';
        }
        // Show success message
        this.$store.dispatch(
          'showSnackBar',
          { text: `The project was ${target} successfully.` }
        );

        // Update project in store
        await this.updateProjectStore();
        this.setMovableProject();

        // Switch to "Details" tab
        if (this.startProject.intent) this.tab = 0;
        else this.startProject.intent = true;
      } catch (error) {
        this.$store.dispatch(
          'showSnackBar',
          { text: `Something went wrong while ${this.project.isApproved ? 'starting' : 'approving'} the project!` }
        );
        console.error(error);
      } finally {
        this.hasApproveIntention = false;
        this.hasSaveIntention = false;
      }
    },
    async rejectPendingProject(emailMessage) {
      this.isSaving = true;
      this.hasRejectIntention = true;

      await this.updateProject();
      await projectService.rejectProject(this.editableProject, emailMessage);
      await this.updateProjectStore();
      this.$store.dispatch('showSnackBar', { text: 'The project was rejected, it\'s now under Projects -> Rejected' });

      this.isSaving = false;
      this.hasRejectIntention = false;
      this.closeModal();
    },
    async manageEmailCampaing(campaingStartDate, coachingNumber) {
      if (this.project.emailCampaign && !campaingStartDate) {
        await projectService.unsubscribeToEmailCampaign(this.project.id);
      } else if (!this.project.emailCampaign && campaingStartDate) {
        const campaignInfo = {
          date: campaingStartDate,
          coachingNumber: coachingNumber || 1
        };
        await projectService.subscribeToEmailCampaign(
          this.project.id, this.project.creator.email, campaignInfo
        );
      }
    },
    /**
     * Saves the changes from the `Details` tab.
     */
    async saveChanges() {
      this.showChangePhaseDialog = false;
      try {
        // Check prerequisites
        if (this.isSaving) {
          throw new Error('The saving process is already running!');
        }
        if (!this.isValid) {
          throw new Error('The input values are invalid!');
        }
        // Mark saving process as running
        this.isSaving = true;
        await this.updateProject();
        // show create certificate if change the phase forward
        if (this.selectedTab === 'move' && this.goNextPhase) {
          this.showCertificateDialog();
        }
        if (this.selectedTab === 'move' && this.selectedPhaseTitle === 'Goldbox') {
          this.tab = 0;
        }
        await this.updateProjectStore();
        if (this.selectedTab === 'move') {
          this.$store.dispatch('showSnackBar', { text: `Project updated and moved to ${this.selectedPhaseTitle}.` });
          this.setMovableProject();
        } else {
          // Display success message
          this.$store.dispatch('showSnackBar', { text: 'Project updated.' });
        }
      } catch (error) {
        // Display error message and log error
        this.$store.dispatch('showSnackBar', { text: 'An error occurred while updating the project!' });
        console.error(error);
      } finally {
        // Mark saving process as completed
        this.isSaving = false;
        translationService.clearTranslation(`Project-${this.project.id}`);
      }
    },
    async updateProjectStore() {
      this.isSaving = true;

      if (await projectService.canIModifyProject(this.project.id)) {
        await projectService.updateDashboardProjectInStore(this.project.id);
        this.editableProject = this.$lodash.cloneDeep(this.project);
      } else {
        this.closeModal();
        await projectService.getDashboardProjects();
      }

      this.isSaving = false;
    },
    switchTab(tab) {
      if (typeof tab === 'string') {
        const foundTab = this.tabs.find((t) => t.key === tab);
        this.tab = foundTab ? this.tabs.indexOf(foundTab) : 0;
      } else {
        this.tab = tab;
      }
    },
    async updateProject() {
      // Create base save object and array with props to unset
      const saveData = {
        id: this.projectId,
        title: this.editableProject.title,
        tagline: this.editableProject.tagline,
        description: this.editableProject.description,
        coaches: this.editableProject.coaches,
        interestedUnit: this.editableProject.interestedUnit,
        tags: this.editableProject.tags,
        campaign: this.editableProject.campaign,
        crossCorporateTags: this.editableProject.crossCorporateTags || [],
        isApproved: this.editableProject.isApproved,
        learningsUrl: this.editableProject.learningsUrl,
        learningsDescription: this.editableProject.learningsDescription,
        coachingLanguages: this.editableProject.coachingLanguages,
      };
      const unsetProps = [];

      // start video upload simultaneously
      await Promise.all([
        this.video('redboxPitch') && this.video('redboxPitch').save()
          .then((video) => {
            saveData.video1min = videoMixin.methods.isYoutubeVideo(video)
              ? video // Youtube string
              : video && video.split('?')[0]; // Remove signature from url
          }),
        (this.selectedTab === 'move' || (!this.isRedBoxIdea && !this.isPending)) && this.video('redboxFundingPitch').save()
          .then((video) => {
            saveData.video8min = video;
          }),
        ((this.selectedTab === 'move' && this.isMovableBlueBox) || this.isBlueBox || this.isGoldBox) && this.video('blueboxPitch').save()
          .then((video) => {
            saveData.blueboxVideo = video;
          }),
        ((this.selectedTab === 'move' && this.isMovableGoldBox) || this.isGoldBox) && this.video('goldboxPitch').save()
          .then((video) => {
            saveData.goldboxVideo = video;
          }),
        (this.selectedTab === 'learnings' && this.project.isAlumni) && this.video('learningsVideo').save()
          .then((video) => {
            saveData.learningsVideo = video;
          })
      ]);
      if (this.imageHasChange) {
        const objectInfo = {
          type: 'Project',
          id: this.projectId,
          field: 'photo'
        };
        saveData.photo = await fileService
          .uploadImage(this.$refs.selectImage[0].image, this.movableProject.photo, 'public-read', objectInfo);
      }

      // Update project phase
      if (this.selectedTab === 'move') {
        if (this.isRedBoxIdea) {
          saveData.fundingGoal = this.movableProject.fundingGoal;
        }
      } else if (!this.isPending && !this.isRedBoxIdea) {
        saveData.fundingGoal = this.editableProject.fundingGoal;
      }
      // Unset interestedUnit
      if (!this.editableProject.interestedUnit) {
        unsetProps.push('interestedUnit');
      }
      // Set or unset campaign
      if (this.editableProject.campaign
        && this.editableProject.campaign.id
        && !this.campaigns
          .some((c) => c.title === this.editableProject.campaign.title)) {
        throw new Error('The selected campaign does not exist!');
      }
      if (!this.editableProject.campaign) {
        unsetProps.push('campaign');
      }

      // Set or unset cross-corporate companies
      const selectedCrossCorporates = [...this.editableProject.crossCorporate].sort();
      if (
        this.hasFeature(FEATURE_NAME.CROSS_CORPORATE)
        && !this.$lodash.isEqual(selectedCrossCorporates, [...this.project.crossCorporate].sort())
      ) {
        if (this.editableProject.crossCorporate.length === 0) {
          unsetProps.push('crossCorporate');
        } else {
          const crossCorporates = this.editableProject.crossCorporate
            .map((cc) => {
              const crossCorporate = this.companies.find((c) => c.id === cc.id);
              if (!crossCorporate) {
                throw new Error('The selected company does not exist!');
              }
              return crossCorporate;
            });
          await projectService.updateCrossCorporateProject(
            this.project.id,
            crossCorporates,
            saveData
          );
        }
      }
      // add, remove or update sponsors
      if (this.deletedSponsors.length) {
        await this.deletedSponsors
          .forEach((sponsor) => sponsorService.deleteSponsor(sponsor.id));
      }
      if (this.updatedSponsors.length) {
        await this.updatedSponsors.forEach((sponsor) => sponsorService
          .updateSponsor(sponsor.id, sponsor.name, sponsor.unit.name, sponsor.amount));
      }
      const createdSponsors = this.selectedTab === 'move' && this.isMovableBlueBox
        ? this.movableProject.sponsors : this.createdSponsors;
      if (createdSponsors.length) {
        const sponsors = createdSponsors.filter((s) => !s.id);
        await sponsors.forEach((sponsor) => sponsorService
          .createSponsor(this.project.id, sponsor.name, sponsor.unit.name, sponsor.amount));
      }
      // add or remove coins
      if (this.coinBalanceDelta > 0) {
        await projectService.addCreditToProject(this.project.bankAccount.id, this.coinBalanceDelta);
      } else if (this.coinBalanceDelta < 0) {
        const amount = Math.abs(this.coinBalanceDelta);
        await projectService.removeCreditFromProject(this.project.bankAccount.id, amount);
      }
      this.coinBalanceDelta = null;
      await phaseService.managePhaseDays(this.project.phase.id, this.phaseDuration);

      this.phaseDuration = null;
      await this.manageEmailCampaing(this.editableProject.emailCampaign,
        this.editableProject.coachingNumber);
      // Save basic project data
      await projectService.updateProject(this.projectId, saveData, unsetProps);
      if (this.selectedTab === 'move') {
        await projectService.changeProjectPhase(this.movableProject.phase.name, this.project);
      }
    },

    /**
     * Returns whether a value fulfills the given length requirements.
     *
     * `maxLength` and `minLength` checks can be disabled by passing `0`.
     *
     * @param {string|any[]} value         The value to validate.
     * @param {boolean}      isOptional    Whether the value is optional.
     * @param {number}       maxLength     The maximal allowed length of the value.
     * @param {number}       [minLength=0] The minimal allowed length of the value.
     *
     * @returns {boolean}
     */
    validateValueWithCounter(value, isOptional, maxLength, minLength = 0) {
      if (maxLength < 0) {
        throw new Error('The maxLength value must be at least 0!');
      }
      if (minLength < 0) {
        throw new Error('The minLength value must be at least 0!');
      }
      if (maxLength > 0 && maxLength < minLength) {
        throw new Error('The maxLength value cannot be smaller than the minLength value!');
      }

      // Warn if no maxLength or minLength bounds are given
      if (maxLength === 0 && minLength === 0) {
        console.warn('No bounds are given!');
      }

      return !(
        (!Array.isArray(value) && !value && !isOptional)
        || (maxLength > 0 && value.length > maxLength)
        || (minLength > 0 && value.length < minLength)
      );
    },
    video(video) {
      return this.$refs[video] ? this.$refs[video][0] : null;
    },
    hasFeature(feature) {
      return this.company.features[feature];
    },
    onImageChange(image) {
      this.imageHasChange = true;
      this.hasImage = !!image;
    },
    markAsAlumni() {
      this.updateProjectStore();
      const lastItem = this.tabs[this.tabs.length - 1];
      this.tab = this.tabs.lastIndexOf(lastItem);
      this.showCertificateDialog();
    },
    showCertificateDialog() {
      this.$store.commit('setProjectCertificate', {
        phaseFullTitle: this.project.phase.title,
        projectId: this.project.id
      });
    },
    async ownerChanged(project) {
      this.showChangeOwnerDialog = false;
      const projectProperties = await userService.updateChangeOwnerStore(project);
      this.form = { ...this.form, ...projectProperties };
      this.updateProjectStore();
    }
  }
};
</script>

<style scoped lang="scss">
label {
  font-size: 12px;
}

.dialog-content {
  max-height: calc(100vh - 272px);
  overflow-y: auto;

  // hide scrollbar because the scrollbar can cause an display error at a
  // cerain window height. This only disables scrollbar in Chrome and Safari (Webkit)
  // but in IE and Firefox there is no need for it, because it doesnt change
  // the width of the element when it applies the scrollbar.
  &::-webkit-scrollbar {
    display: none;
  }
}

.phase-chip {
  overflow: unset;
}

::v-deep .ql-editor{
  height: 100px;
}

::v-deep .two-columns > .col {
  margin-top: 8px;

  &:nth-child(even) {
    padding-right: 1rem;
  }
}
</style>
